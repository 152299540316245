import React from "react"
import Seo from "src/utils/seo"
import Layout from "components/Layout/Layout"
import SettingsManager from "components/SettingsManager/SettingsManager"
import PlaceCm from "components/PlaceCm/PlaceCm"

export default function Page() {
    return(
        <React.Fragment>
            <Seo title="Placement Reports - Campaigns" />
            <Layout showActions={true} showBulk={false}>
                <PlaceCm page="placeCm" />
            </Layout>
            <SettingsManager/>
        </React.Fragment>
    )
}